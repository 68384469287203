.pin-link {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  width: fit-content;
  padding: 10px;
  text-decoration-line: underline;
}
.account-btn {
  width: 100%;
  /* border-bottom: 1px solid var(--border); */
  background-color: transparent;
  background: none;
  border: none;
  border-bottom: 1.5px solid var(--black-tints-4);
  padding: 10px;
}
.map-container {
  flex: 1;
  border-radius: 8px;
  background-color: var(--black-tints-4);
}
.indicator div {
  flex: 1;
  height: 2px;
  background-color: var(--neutrals-light-yellow);
}
.indicator span {
  width: 32px;
  height: 32px;
  background-color: var(--neutrals-light-yellow);
  border-radius: 50%;
  position: relative;
  color: var(--main-yellow);
}
.indicator.completed span,
.indicator.indicator.completed div {
  background-color: #4caf50;
  border: none;
  color: white;
}
.indicator.current span:first-child:after {
  content: "";
  width: 120%;
  height: 120%;
  background-color: transparent;
  position: absolute;
  top: -12%;
  left: -12%;
  border-radius: 50%;
  border: 1px solid var(--main-yellow);
}
.indicator.current span:first-child,
.indicator.indicator.current div {
  background-color: var(--main-yellow);
  color: white;
}
.steps-details {
  max-width: 30%;
  margin-right: 50px;
}
.submit-menu-card {
  border-bottom: 1px dashed var(--black-tints-1);
  padding-bottom: 20px;
}
.img-cnt img {
  object-fit: cover;
}
.review-star {
  width: 35px;
  height: 35px;
  border: 1px solid #f9f9f9;
  background-color: #f9f9f9;
  border-radius: 4px;
}
.review-star svg path {
  fill: var(--black-tints-4);
}
.review-star.marked svg path {
  fill: var(--main-yellow);
}
.review-star.marked {
  background-color: var(--yellow-tints-1);
  border-color: var(--main-yellow);
}
.img-pain {
  width: 100%;
  height: 210px;
  background-color: var(--black-tints-4);
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}
.img-pain:hover img {
  scale: 1.2;
}
.img-pain img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s all ease-in-out;
}
.search-profile {
  margin-bottom: 10px;
  border-bottom: 0.5px solid #d8d8d8;
  padding-bottom: 10px;
  cursor: pointer;
}
.search-profile img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 10px;
}
.search-rusults {
  max-height: 250px;
  overflow: auto;
}
.material-search-item {
  margin-bottom: 10px;
  border-bottom: 0.5px solid #d8d8d8;
}
.material-search-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
}
.material-search-item .btn {
  margin-left: auto;
}
.menu-row input {
  max-width: 50px;
  height: 30px;
  margin: 0px 5px;
  border: 1px solid #d8d8d8;
  text-align: center;
  outline: none;
}
.info-1 .profile-img {
  width: 250px;
  height: 250px;
  border-radius: 4px;
  cursor: pointer;
}
.v-user-info {
  gap: 60px;
  flex-wrap: wrap;
}
.concept-img-head img {
  height: 100%;
  width: 100%;
}
.width-50 {
  width: 50%;
}
@media (max-width: 760px) {
  .action-btns {
    flex-wrap: wrap;
  }
  .map {
    min-height: 400px;
  }
  .steps-details {
    max-width: 100%;
    margin-right: 0px;
  }
}
@media (max-width: 760px) {
  .map {
    min-height: 400px;
  }
  .indicator span {
    display: none;
  }
  .indicator div {
    height: 6px;
    border-radius: 4px;
  }
  .indicator.skiped div {
    background-color: var(--alerts-danger);
  }
}
