.item-img img {
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: cover;
  background-color: var(--black-tints-4);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.material-info,
.fields-list {
  gap: 15px;
  width: 100%;
}
.details .summary-info {
  width: 48%;
}

@media (max-width: 760px) {
  .material-info,
  .fields-list {
    flex-direction: column;
  }
  .item-img img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
}
