.summary-info {
  padding: 12px 0px;
  border-bottom: 1px solid var(--yellow-tints-1);
}
.summary-info span:first-child {
  margin-bottom: 5px;
}
.summary,
.sales-items {
  flex: 1;
}
.summary {
  max-width: 390px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.img-cart {
  width: 64px;
  height: 48px;
  border-radius: 8px;
  background-color: var(--black-tints-4);
  margin-right: 10px;
}
.icart-info {
  width: 100%;
  gap: 10px;
}
.sales-items {
  padding-right: 40px;
}
.itm-lst {
  flex: 1;
}
.counter span {
  width: 45px;
  text-align: center;
}
.counter {
  width: fit-content;
}
.menu-row {
  border-bottom: 1px solid var(--yellow-tints-1);
  margin-bottom: 5px;
}
.menu-row td {
  padding: 12px 0px !important;
}
.t-check {
  width: 35px;
}
@media (max-width: 760px) {
  .sales-input {
    flex-direction: column;
  }
  .summary {
    max-width: 100%;
  }
  .sales-items {
    padding-right: 0px;
  }
}
